import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AboutModal } from './modal'
import { rhythm, scale } from '../utils/typography'
import { Divider } from 'semantic-ui-react'
import { clearAllPersistedAnswer } from '../utils/persistAnswers'
import {
  shouldRenderContributor,
  shuffle
} from '../utils/shouldRenderContributor'
import RightModal from './right-modal'

const Layout = props => {
  const [loading, stopLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [contributors, setContributors] = useState([])
  const headerRef = useRef()
  const { location, title, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  useEffect(() => {
    fetch(
      'https://api.github.com/repos/nas5w/typeofnan-javascript-quizzes/contributors?per_page=1000'
    )
      .then(res => res.json())
      .then(users => {
        setContributors(
          shuffle(
            users.filter(user =>
              shouldRenderContributor(user.id)
            )
          )
        )
        stopLoading(false)
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    location.pathname !== '/' &&
      headerRef.current.scrollIntoView()
  }, [location])

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          fontSize: '30px',
          marginBottom: '20px',
          marginTop: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = null
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
      }}
    >
      <Link to="/">Accueil</Link> |{' '}
      <a
        href="https://private.devtheory.fr/demystifie-le-javascript?utm_source=challenge"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cours Gratuit
      </a>{' '}
      |{' '}
      <a
        href="https://www.youtube.com/c/DevTheory"
        target="_blank"
        rel="noopener noreferrer"
      >
        Youtube
      </a>{' '}
      <header>{header}</header>
      <main ref={headerRef}>{children}</main>
      <button
        onClick={() => {
          window.confirm(
            'Voulez-vous vraiment effacer toutes les réponses?'
          ) && clearAllPersistedAnswer()
          window.location.reload()
        }}
        className="ui red basic button"
      >
        Réinitialiser toutes les réponses
      </button>
      <button
        className="ui basic button"
        style={{ marginLeft: '10px' }}
        onClick={() => setModalOpen(true)}
      >
        Proposer une question à ce challenge &raquo;
      </button>
      <RightModal />
      <AboutModal
        modalIsOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      />
      <footer style={{ fontSize: '14px' }}>
        <Divider />
        <p>
          Ce challenge est fait par{' '}
          <strong>DevTheory</strong>.<br />
          Suivez-nous sur{' '}
          <a href={`https://twitter.com/devtheory_`}>
            Twitter
          </a>
          ! <br />
          Nous avons également{' '}
          <a href="https://www.youtube.com/channel/UCgJERWtcvu-JiPq5wBkYF1g">
            une chaine Youtube
          </a>
          !
        </p>
        <p>
          <strong>
            Remarque sur le stockage des réponses :{' '}
          </strong>
          Vos réponses à ces questions du challenge sont
          stockées localement dans le local storage de votre
          navigateur.
        </p>
        © {new Date().getFullYear()}, Fait avec{' '}
        <a href="https://www.gatsbyjs.org">Gatsby</a> | Basé
        sur le travail de{' '}
        <a href="https://typeofnan.dev/">typeofnan</a>
      </footer>
    </div>
  )
}

export default Layout
