import React from 'react'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px'
  }
}

Modal.setAppElement('#___gatsby')

const AboutModal = props => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h1>Proposer une question à ce challenge</h1>
      <p>
        Bien entendu ce challenge est voué à s'agrandir, et
        votre aide sera la bienvenue !
      </p>
      <p>
        N'hésitez pas à nous proposer une question dès que
        vous pensez à celle-ci, une fois présente sur le
        site web: votre nom/pseudonyme ou "Anonyme" sera
        présent sur la page de cette question.
      </p>
      <p>
        Afin de faciler le processus, nous avons créé un
        GForm: <br />
        <a
          href="https://forms.gle/DYceNKoH9X2ieQFJA"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accéder au GForm et proposer ma question
        </a>
      </p>
      <button
        className="ui button"
        onClick={props.closeModal}
      >
        Pas maintenant
      </button>
    </Modal>
  )
}

const ClearAnswerModal = props => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      contentLabel="Clear My Answer"
    >
      <h1 className="ui header red">Attention !</h1>
      <p>
        Certain de tout vouloir supprimer votre réponse à
        cette question ?
      </p>
      <button
        className="ui button blue"
        onClick={props.closeModal}
      >
        Non
      </button>
      <button
        className="ui button red"
        onClick={props.clearAnswer}
      >
        Oui
      </button>
    </Modal>
  )
}

export { AboutModal, ClearAnswerModal }
