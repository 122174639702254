import React, { Component } from 'react'
import {
  Card,
  Icon,
  Image,
  Button,
  Transition
} from 'semantic-ui-react'

class ModalExampleSize extends Component {
  state = { open: false }

  setCookie(name,value,days) {
    if (typeof document !== 'undefined') {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
  }
  getCookie(name) {
    if (typeof document !== 'undefined') {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)===' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    }
  }

  show = () => this.setState({ open: true })
  close = () => {
    this.setState({ open: false })
    this.setCookie('modalSeen', true, 7);
  }

  // composant qui show après 20 secondes
  componentWillMount() {
    var that = this
    if (!this.getCookie('modalSeen')) {
      setTimeout(function() {
        that.show()
      }, 20000)
    }
  }

  render() {
    const { open } = this.state

    const cardStyle = {
      fontFamily: 'Arial',
      width: '320px',
      position: 'fixed',
      right: '10px',
      bottom: '10px',
      display: this.state.open ? 'block' : 'none'
    }

    return (
      <div>
        <Transition
          visible={open}
          animation="scale"
          duration={500}
        >
          <Card style={cardStyle}>
            <a
              href="https://private.devtheory.fr/demystifie-le-javascript?utm_source=challenge"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/gif_DJS.gif"
                style={{ marginBottom: '0px' }}
              />
            </a>
            <Card.Content>
              <Card.Header>Série de vidéos</Card.Header>
              <Card.Meta>Cours gratuit</Card.Meta>
              <Card.Description>
                Démystifiez le JavaScript grâce à 7 notions
                avancées en vidéo.
              </Card.Description>
            </Card.Content>

            <Card.Content extra>
              <div className="ui two buttons">
                <Button
                  onClick={() => {
                    window.open(
                      'https://private.devtheory.fr/demystifie-le-javascript?utm_source=challenge',
                      '_blank'
                    );
                    this.close();
                  }}
                  basic
                  color="green"
                >
                  J'en profite
                </Button>
                <Button
                  onClick={this.close}
                  basic
                  color="red"
                >
                  Pas besoin
                </Button>
              </div>
            </Card.Content>
            <Card.Content
              style={{ fontSize: '13px' }}
              extra
            >
              <Icon name="user" />
              351 déjà inscrits
            </Card.Content>
          </Card>
        </Transition>
      </div>
    )
  }
}

export default ModalExampleSize
